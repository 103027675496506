import React from 'react';
import { string } from 'prop-types';
import { Box, AspectRatio, Text, Button, Center, Link } from '@chakra-ui/react';
import ImgPattern from '@/assets/pattern/flower.png';
import WithAnimation from '@components/Common/WithAnimation';

const DEFAULT_BOTTOM = {
  color: 'white',
  marginTop: '12px',
  boxShadow: 'xl',
  fontSize: '14px',
  fontWeight: 'light',
  borderRadius: '6px',
  textTransform: 'uppercase',
  background: BUTTON_GRADIENT,
  ...BUTTON_PROPS,
};

import {
  THE_BRIDE,
  YOUTUBE_EMBED,
  YOUTUBE_LINK,
} from '@/constants';
import { BG_PRIMARY_GRADIENT, BUTTON_GRADIENT, BUTTON_PROPS } from '@/constants/colors';
import txt from './locales';
import { ID_YOUTUBE_LIVE } from '@/constants/identifier';

/**
 * @function YoutubeLiveSection 
 * Render Live Streaming component
 * @returns {JSX.Element}
 */
function YoutubeLiveSection({ lang, ...rest }) {

  return (
    <Box position="relative" textAlign="center" bgImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_GRADIENT}), url(${ImgPattern})`} bgColor="bgPrimary" color="mainColorText" {...rest} name={ID_YOUTUBE_LIVE}>
      {/* LIVE STREAMING SECTION */}
      <Box width="100%" padding="32px">
        <Text fontFamily="heading" fontSize="4xl" marginBottom="16px">
          {txt.title[lang]}
        </Text>
        {/* Live stream section */}
        <WithAnimation>
          <AspectRatio maxW="560px" ratio={16 / 9} boxShadow="xl">
            <iframe
              title={`Live wedding of ${THE_BRIDE}`}
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '8px' }}
            />
          </AspectRatio>
        </WithAnimation>
        <WithAnimation>
          <Text textAlign="center" fontSize="md" color="mainColorText" margin="16px 0">
            {txt.problem[lang]}
          </Text>
        </WithAnimation>
        <Center>
          <Link href={YOUTUBE_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
            <Button
              size="sm"
              fontWeight="normal"
              variant="solid"
              fontSize="14px"
              bgColor="bgSecondary"
              color="secondaryColorText"
              marginBottom="16px"
              //fontFamily="body"
              {...DEFAULT_BOTTOM}
            >
              {txt.open[lang]}
            </Button>
          </Link>
        </Center>
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);
